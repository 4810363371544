<template>
  <div id="sidenav" class="sidenav">
    <div id="imgNav" class="imgNav">
      <div class="card">
        <div class="card-body" v-if="this.$store.state.division">
          <div style="font-size: 1.2em; color: chocolate">
            {{ this.$store.state.division.dist_name }}
          </div>
          <div>{{ this.$store.state.division.address }}</div>
        </div>
      </div>
    </div>

    <div id="mnu" class="mnu" v-if="!this.$store.state.division.id">
      <div class="card" style="margin: 10px; padding: 30px 20px">
        PLEASE SELECT A DIVISION
      </div>
    </div>

    <div id="mnu" class="mnu" v-if="this.$store.state.division.id != null">
      <nav class="tree-nav">
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">
            <router-link :to="'/'" class="tree-nav__item-title">Home </router-link>
          </summary>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Sales</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Invoices</summary>
            <div class="tree-nav__item">
              <router-link :to="'/invoice'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Invoice</router-link
              >
              <router-link :to="'/invoices_ov'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Invoice Overview
              </router-link>
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Reports</summary>
            <div class="tree-nav__item">
              <router-link :to="'/salesreport'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Sales Report</router-link
              >
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Inventory</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Receive Notes</summary>
            <div class="tree-nav__item">
              <router-link :to="'/inventory_grn'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Receive Note
              </router-link>
              <router-link :to="'/inventory_grn_ov'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> History
              </router-link>
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Loading Sheet</summary>
            <div class="tree-nav__item">
              <router-link :to="'/inventory_loading_sheet'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Loading Sheet
              </router-link>
            </div>
          </details>

          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Products & Items</summary>
            <div class="tree-nav__item">
              <router-link :to="'/item_master'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Item List
              </router-link>
              <router-link :to="'/item_categories'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Categories
              </router-link>
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Stock</summary>
            <div class="tree-nav__item">
              <router-link :to="'/inventory_stock_bal'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Balance
              </router-link>
              <router-link :to="'/inventory_stock_tr'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Transfer
              </router-link>
              <router-link :to="'/inventory_stock_location'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Locations
              </router-link>
            </div>
          </details>
        </details>
        <details class="tree-nav__item is-expandable">
          <summary class="tree-nav__item-title">Customers</summary>
          <div class="tree-nav__item">
            <router-link :to="'/customers'" class="tree-nav__item-title"
              ><i class="bi bi-dot"></i> Customers</router-link
            >
            <router-link :to="'/inventory_loading_routes'" class="tree-nav__item-title"
              ><i class="bi bi-dot"></i> Routes
            </router-link>
          </div>
        </details>

        <details class="tree-nav__item is-expandable" v-if="AdminMenu">
          <summary class="tree-nav__item-title">System</summary>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Users</summary>
            <div class="tree-nav__item">
              <router-link :to="'/users'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Users</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable">
            <summary class="tree-nav__item-title">Divisions</summary>
            <div class="tree-nav__item">
              <router-link :to="'/divisions'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Divisions</router-link
              >
            </div>
          </details>
          <details class="tree-nav__item is-expandable" v-if="isSystem">
            <summary class="tree-nav__item-title">Admin</summary>
            <div class="tree-nav__item">
              <router-link :to="'/test'" class="tree-nav__item-title"
                ><i class="bi bi-dot"></i> Test Page</router-link
              >
            </div>
          </details>
        </details>
      </nav>
    </div>
  </div>
</template>
<script>
import store from "@/store";
export default {
  setup() {},
  data() {
    return {
      AdminMenu: false,
      isAdmin: false,
      isSystem: false,
      role: null,
      isDivisionProduction: false,
      isDivistionSales: false,
      keyword: null,
      division: store.state.division,
    };
  },
  mounted() {
    this.checkSystem();
  },
  methods: {
    async checkSystem() {
      this.role = store.state.user.role;
      if (this.role == "System") {
        this.isSystem = true;
      }
      if (this.role == "System" || this.role == "Admin") {
        this.AdminMenu = true;
      }
    },
  },
};
</script>
<style scoped>
.sidenav {
  transition: 00.5s;
  width: 270px;
  left: 0;
  float: left;
  flex-wrap: wrap;
  height: 100vh;
  overflow: scroll;
  background-color: rgb(37, 37, 37);
  font-size: 0.9em;
  border-right: thin solid #5f5f5f;
}

.mImg {
  width: 100%;
}

.imgNav {
  height: auto;
}

.mnu {
  height: inherit;
}

.accordion-item:last-of-type,
.accordion-button.collapsed,
.accordion-item:first-of-type {
  border-radius: 0;
}
</style>
