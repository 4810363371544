<template>
  <component v-bind:is="layout"></component>
  <notifications position="top right" />
</template>

<script>
import AppLayout from "./views/layouts/AppLayout.vue";
import LoginLayout from "./views/layouts/LoginLayout.vue";
import axios from "axios";
export default {
  setup() {},
  computed: {
    layout() {
      return this.$store.getters.layout;
    },
  },
  components: {
    "app-layout": AppLayout,
    "login-layout": LoginLayout,
  },
  async beforeCreate() {
    this.$store.commit("division", { id: null });
    var token = sessionStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      try {
        const response = await axios.post("verify");
        //  console.log(response.data);
        if (response.data.status == true) {
          this.$store.dispatch("user", response.data.user);

          this.$router.push("/");
        }
      } catch (err) {
        if (err) {
          this.loading = false;
        }
      }
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
