import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/App/Home/HomeView"),
  },

  {
    path: "/nopermission",
    name: "No Permission",
    component: () => import("../views/App/Home/PermissionError"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/LoginView"),
  },
  {
    path: "/section_selection",
    name: "Section Selection",
    component: () => import("../views/Auth/SectionView"),
  },
  {
    path: "/users",
    name: "Users",
    component: () => import("../views/App/Users/UsersView"),
  },
  {
    path: "/users_profile",
    name: "My Profile",
    component: () => import("../views/App/Users/UserProfile"),
  },

  {
    path: "/divisions",
    name: "Divisions",
    component: () => import("../views/App/Divisions/DivisionsView"),
  },
  {
    path: "/customers",
    name: "Customers",
    component: () => import("../views/App/Customers/CustomersView"),
  },
  {
    path: "/item_master",
    name: "Item Master",
    component: () => import("../views/App/Inventory/Items/ItemMaster"),
  },
  {
    path: "/item_categories",
    name: "Item Categories",
    component: () => import("../views/App/Inventory/Items/ItemCategories"),
  },
  {
    path: "/inventory_grn",
    name: "Good Receive Note",
    component: () => import("../views/App/Inventory/Grn/GrnView"),
  },
  {
    path: "/inventory_grn_ov",
    name: "Good Receive Notes",
    component: () => import("../views/App/Inventory/Grn/GrnOv"),
  },
   {
    path: "/inventory_stock_bal",
    name: "Stock Balance",
    component: () => import("../views/App/Inventory/Stock/StockBal"),
  },
  {
    path: "/inventory_stock_tr",
    name: "Stock Transfer",
    component: () => import("../views/App/Inventory/Stock/StockTr"),
  },
     {
    path: "/inventory_stock_location",
    name: "Stock Locations",
    component: () => import("../views/App/Inventory/Stock/LocationsView"),
  },
  {
    path: "/inventory_loading_routes",
    name: "Loding Routes",
    component: () => import("../views/App/Inventory/LoadingSheet/RoutesView"),
  },
  {
    path: "/invoice",
    name: "Sales Invoice",
    component: () => import("../views/App/Invoice/InvoiceView"),
  },
    {
    path: "/invoices_ov",
    name: "History",
    component: () => import("../views/App/Invoice/InvoiceOv"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import store from "@/store";
router.beforeEach((to, from, next) => {
  document.title = "MAHAWATHTHA | " + to.name;
  if (to.name !== "Login" && !store.state.user) {
    next({ name: "Login" });
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});
export default router;
