import { createStore } from "vuex";

function initialState() {
  return {
    token: null,
    user: null,
    pageTitle: "Dashboard",
    StatusMsg: "App Started!",
    division: { id: null, name: null, address: null },
  };
}

export default createStore({
  state: {
    layout: "login-layout",
    initialState,
  },
  getters: {
    layout(state) {
      return state.layout;
    },
    user: (state) => {
      return state.user;
    },
    token: (state) => {
      return state.token;
    },
    pageTitle: (state) => {
      return state.pageTitle;
    },
    StatusMsg: (state) => {
      return state.StatusMsg;
    },
    division: (state) => {
      return state.division;
    },
  },
  mutations: {
    SET_LAYOUT(state, payload) {
      state.layout = payload;
    },
    user(state, user) {
      state.user = user;
    },
    token(state, token) {
      state.token = token;
    },
    pageTitle(state, pageTitle) {
      state.pageTitle = pageTitle;
    },
    StatusMsg: (state, StatusMsg) => {
      state.StatusMsg = StatusMsg;
    },
    division: (state, division) => {
      state.division = division;
    },
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    user(context, user) {
      context.commit("user", user);
    },
    token(context, token) {
      context.commit("token", token);
    },
    pageTitle(context, pageTitle) {
      context.commit("pageTitle", pageTitle);
    },
    StatusMsg(context, StatusMsg) {
      context.commit("StatusMsg", StatusMsg);
    },
    division(context, division) {
      context.commit("division", division);
    },
  },
  modules: {},
});
